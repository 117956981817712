const Ajax = {
    init: () => {
        $('.js-ajax-registration').off().on('submit', function(e) {
            e.preventDefault();

            $.ajax({
                method: "POST",
                url: "/ajax-registration.php",
                data: {
                    registration_type: $('input[name="registration_type"]:checked').val(),
                    registration_name: $('#registration_name').val(),
                    registration_email: $('#registration_email').val()
                }
            }).done(function(msg) {
                if (msg) {
                    $('.js-ajax-registration').parent().append("<p class='popup__text'>Odeslání registrace se zdařilo.</p>");
                    $('.js-ajax-registration').remove();
                } else {
                    alert('Registraci se z technických důvodů nepodařilo uložit.');
                }
            });
        });

        $('.js-ajax-contact').off().on('submit', function(e) {
            e.preventDefault();

            $.ajax({
                method: "POST",
                url: "/ajax-contact.php",
                data: {
                    contact_name: $('#contact_name').val(),
                    contact_email: $('#contact_email').val(),
                    contact_message: $('#contact_message').val()
                }
            }).done(function(msg) {
                if (msg) {
                    $('.js-ajax-contact').parent().append("<p class='popup__text'>Odeslání Vašeho dotazu se zdařilo.</p>");
                    $('.js-ajax-contact').remove();
                } else {
                    alert('Váš dotaz se z technických důvodů nepodařilo uložit.');
                }
            });
        });
    },
};

module.exports = Ajax;
