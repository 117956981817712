const Antispam = {
    init: () => {
        // Ticket pro odeslani formulare
        $('form').submit(function() {
            $('#pgcms1').val('pgcmsticket');
        });
    },
};

module.exports = Antispam;
