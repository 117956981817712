const Emailreplace = {
    init: () => {
        let links = document.getElementsByTagName('a');

        for (let i=0; i<links.length; i++) {
            if (links[i].title) {
                links[i].title = links[i].title.replace(/ \[at\] /g, '@');
                links[i].title = links[i].title.replace(/ \[dot\] /g, '.');
            }

            links[i].innerHTML = links[i].innerHTML.replace(/ \[at\] /g, '@');
            links[i].innerHTML = links[i].innerHTML.replace(/ \[dot\] /g, '.');

            if (links[i].getAttribute('href')) {
                links[i].setAttribute('href', links[i].getAttribute('href').replace(/%20%5Bat%5D%20/g, '@'));
                links[i].setAttribute('href', links[i].getAttribute('href').replace(/ \[at\] /g, '@'));
                links[i].setAttribute('href', links[i].getAttribute('href').replace(/%20%5Bdot%5D%20/g, '.'));
                links[i].setAttribute('href', links[i].getAttribute('href').replace(/ \[dot\] /g, '.'));
            }
        }

        let inputs = document.getElementsByTagName('input');

        for (let i=0; i<inputs.length; i++) {
            inputs[i].value = inputs[i].value.replace(/ \[at\] /g, '@');
            inputs[i].value = inputs[i].value.replace(/ \[dot\] /g, '.');
        }

        let textareas = document.getElementsByTagName('textarea');

        for (let i=0; i<textareas.length; i++) {
            textareas[i].value = textareas[i].value.replace(/ \[at\] /g, '@');
            textareas[i].value = textareas[i].value.replace(/ \[dot\] /g, '.');
        }
    },
};

module.exports = Emailreplace;
