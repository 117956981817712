const Popup = {
    init: () => {
        $('.js-popup-close').off().on('click', function(e) {
            e.preventDefault();
            var target = $(this).data('popup');

            if (target) {
                $('html').removeClass(target);
            }
        });

        $('.js-popup-show').off().on('click', function(e) {
            e.preventDefault();
            var target = $(this).data('popup');

            if (target) {
                $('html').addClass(target);
            }
        });

        $(document).keyup(function(e) {
            if (e.key === "Escape") {
                $('html').removeClass('show-registration').removeClass('show-contact');
            }
        });
    },
};

module.exports = Popup;
