const components = [
    'popup',
    'antispam',
    'emailreplace',
    'scroll',
    'ajax',
];

const App = {
    init() {
        components.forEach((component) => {
            let comp = require('components/' + component);
            comp.init();
        });
    },
};

module.exports = App;
