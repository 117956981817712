function scrollPageTo(to, duration=500, offset=0) {
    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    const easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };

    return new Promise((resolve, reject) => {
        const element = document.scrollingElement;

        if (typeof to === 'string') {
            to = document.querySelector(to) || reject();
        }
        if (typeof to !== 'number') {
            to = to.getBoundingClientRect().top + element.scrollTop;
        }

        let start = element.scrollTop,
            change = to - start + parseInt(offset),
            currentTime = 0,
            increment = 20;

        const animateScroll = function() {
            currentTime += increment;
            let val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;

            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            } else {
                resolve();
            }
        };

        animateScroll();
    });
}


const Scroll = {
    init: () => {
        // Scroll stranky na nastaveny element
        $('.js-scroll-to').off().on('click', function(e) {
            e.preventDefault();

            var $this = $(this),
                target = $this.data('scroll_target'),
                offset = $this.data('offset');

            if (!offset) {
                offset = 0;
            }

            scrollPageTo(target, 600, offset);
        });
    },
};

module.exports = Scroll;
